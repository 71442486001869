import * as SERVICES from "./service";

export const districtsService = async () => {
  return await SERVICES.getDistricts()
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const slotsService = async (district) => {
  return await SERVICES.getSlots(district)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};