import { API } from "./Constants";
import { axiosInstance, covidAxiosInstance } from "../../tools";

export const getDistricts = async () => {
  return await axiosInstance.get(API.GET_DISTRICTS);
};

export const getSlots = async (district) => {
  let d = new Date();
  let datestring = ("0" + d.getDate()).slice(-2) + "-" + ("0"+(d.getMonth()+1)).slice(-2) + "-" +d.getFullYear()
  return await covidAxiosInstance.get(API.GET_SLOTS+`?district_id=${district}&date=${datestring}`);
};