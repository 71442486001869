import axios from "axios";
import config from "../config";

const configureAxios = () => {
  return axios.create({
    baseURL: config("app.base_url"),
    timeout: 60000,
  });
};


const covidConfigureAxios = () => {
  return axios.create({
    baseURL: config("app.covid_base_url"),
    timeout: 60000,
  });
};


export const axiosInstance = configureAxios();

export const covidAxiosInstance = covidConfigureAxios();

axiosInstance.interceptors.request.use(
  (config) => {
    if (process.env.NODE_ENV === "development") {
      console.log("Config ======>", config);
    }
    return config;
  },
  (error) => {
    if (process.env.NODE_ENV === "development") {
      console.log("Error ======>", error);
    }
    return Promise.reject(error);
  },
);

covidAxiosInstance.interceptors.request.use(
  (config) => {
    if (process.env.NODE_ENV === "development") {
      console.log("Config ======>", config);
    }
    return config;
  },
  (error) => {
    if (process.env.NODE_ENV === "development") {
      console.log("Error ======>", error);
    }
    return Promise.reject(error);
  },
);

axiosInstance.interceptors.response.use(
  (res) => {
    if (process.env.NODE_ENV === "development") {
      console.log("Response ======>", res);
    }
    return res.data;
  },
  (err) => {
    if (process.env.NODE_ENV === "development") {
      console.log("ERROR ======>", err.response);
    }
    return Promise.reject(err);
  },
);

covidAxiosInstance.interceptors.response.use(
  (res) => {
    if (process.env.NODE_ENV === "development") {
      console.log("Response ======>", res);
    }
    return res.data;
  },
  (err) => {
    if (process.env.NODE_ENV === "development") {
      console.log("ERROR ======>", err.response);
    }
    return Promise.reject(err);
  },
);