import React, { Component } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Dashboard from './containers/Dashboard';
import ErrorComponent from './components/ErrorComponent';

//Constans
import { ROUTES } from "./constants";

class AppRouter extends Component {
  render() {
    return (
      <div className="App">
          <BrowserRouter>
            <Switch>
              <Route
                exact
                path={ROUTES.PAGENOTFOUND}
                render={(props) => <ErrorComponent
                  {...props}
                  pageTitle="Page Not Found"
                  errorTitle="404"
                  errorMessage="Page not found"
                />}
              />
              <Route path={ROUTES.DASHBOARD} component={Dashboard} />
            </Switch>
          </BrowserRouter>
          </div>
    );
  }
}

export default AppRouter;
