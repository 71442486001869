import React, { useEffect, useState } from "react";
import { Select, notification, Radio, Spin, Button, Table } from 'antd';
import { PageTitle } from "../../components/PageTitle";
//Services
import * as DM from "./dataManager";
import { LoadingOutlined } from "@ant-design/icons";
import logo from '../../assets/images/logo.svg';
import eighteen from '../../assets/images/eighteen.svg';
import forty_five from '../../assets/images/forty_five.svg';
import syringe from '../../assets/images/syringe.svg';
import empty from '../../assets/images/empty.svg';

const { Option } = Select;
const antIcon = <LoadingOutlined style={{ fontSize: 42 }} spin />;
function DashboardComponent() {
    const [loading, setLoading] = useState(false);
    const [district, setDistrict] = useState(null);
    const [cities, setCities] = useState([]);
    const [slots, setSlots] = useState([]);
    const [age, setAge] = useState(18);


    function onDistrictChange(value) {
        setDistrict(value);
    }

    function onAgeChange(e) {
        setAge(e.target.value);
    }

    useEffect(() => {
        getDistricts()
    }, []);

    const getFilteredSlots = (data) => {
        let filtered_response = data.filter(function (res) {
            let ans = false;
            res.sessions.every(session => {
                if (session.min_age_limit === age && session.available_capacity > 0) {
                    ans = true;
                    return false;
                }
                return true;
            });
            return ans;
        });
        setSlots(filtered_response)
    }

    const getSlots = () => {
        setLoading(true);
        DM.slotsService(district)
            .then((res) => {
                getFilteredSlots(res.centers);
                setLoading(false);
            })
            .catch((error) => {
                if (error?.response?.data?.message) {
                    notification.error({
                        message: error.response.data.message,
                        duration: 2
                    })
                }
                else {
                    notification.error({
                        message: "Something went wrong",
                        duration: 2
                    })
                }
                setLoading(false)
            });
    };

    useEffect(() => {
        if (age && district)
            getSlots();
    }, [district, age]);

    const getDistricts = () => {
        setLoading(true);
        DM.districtsService()
            .then((res) => {
                setCities(res.data.data)
                setLoading(false)
            })
            .catch((error) => {
                if (error?.response?.data?.message) {
                    notification.error({
                        message: error.response.data.message,
                        duration: 2
                    })
                }
                else {
                    notification.error({
                        message: "Something went wrong",
                        duration: 2
                    })
                }
                setLoading(false)
            });
    };

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            render: (value) => {
                return <div style={{wordBreak: 'break-word'}}>{value}</div>
            }
        },
        {
            title: 'Address',
            dataIndex: 'address',
            render: (value, record) => {
                return <div style={{wordBreak: 'break-word'}}>{`${value}, ${record.district_name}, ${record.state_name}, ${record.pincode}`}</div>
            }
        }
    ];

    return (
        <div>
            <PageTitle title="Vaccine Slots" />
            {loading ?
                <div className="spinner fx fx--ai-c fx--jc-c">
                    <Spin indicator={antIcon} />
                </div> :
                <div>
                    <div className="logo">
                        <img src={logo} alt="Hudle Logo" />
                    </div>
                    <div className="text">
                        COVID-19 Vaccine Slot Finder
                    </div>
                    <div className="district-search">
                        <Select
                            showSearch
                            placeholder="Search by District"
                            optionFilterProp="children"
                            onChange={onDistrictChange}
                            value={district}
                            dropdownClassName="district-search-dropdown"
                        >
                            {
                                cities.map(dist => {
                                    return <Option value={dist.id} key={dist.id}>{dist.name}</Option>
                                })
                            }
                        </Select>
                    </div>
                    <div className="age-radio-button">
                        <Radio.Group onChange={onAgeChange} value={age}>
                            <div className={"age-radio-common" + (age === 18 ? " age-selected" : " age-not-selected")}>
                                <Radio value={18}><span className="age-label">18-44</span><span className="age-image"><img src={eighteen} alt="18 above" /></span></Radio>
                            </div>
                            <div className={"age-radio-common" + (age === 45 ? " age-selected" : " age-not-selected")}>
                                <Radio value={45}><span className="age-label">45+</span><span className="age-image"><img src={forty_five} alt="45 above" /></span></Radio>
                            </div>
                        </Radio.Group>
                    </div>
                    {!district && <div className="select-district-text">
                        Enter your district, to check vaccine availability.
                    </div>
                    }
                    {district && age && slots.length > 0 && <div className="vaccine-available">
                        <img src={syringe} alt="Syringe" />
                        {/* <div className="congratulations">
                            Congratulations!
                        </div> */}
                        <div className="vaccine-count">
                            There are <span className="count">{slots.length}</span> vaccine centres available in your district!
                        </div>
                        <Table columns={columns} dataSource={slots} size="small" />
                        <a href="https://selfregistration.cowin.gov.in/" target="_blank" rel="noreferrer"><Button className="go-to-cowin"><span className="cowin-button-text">Book On CoWIN</span></Button></a>
                    </div>
                    }

                    {district && age && slots.length <= 0 && <div className="no-vaccine-found">
                        <img src={empty} alt="Empty" />
                        <div className="oops">
                            Sorry!
                        </div>
                        <div className="vaccine-not-fount-text">
                            No vaccine slots are available in your district currently
                        </div>
                    </div>
                    }
                </div>
            }
        </div>
    )
}
export default DashboardComponent;
